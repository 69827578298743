<template>
  <div class="ChangePasswordhistory">
    <div class="link-div-top">
      <router-link to="./top" class="page_link">Home</router-link> > <router-link to="./ChangePasswordPage" class="page_link">Password Change Page</router-link> > Password is in history or did not pass complexity checks
    </div>
    <br /><br /><br />
    <div class="qa">
      <h1>Q. What is "Password is in history or did not pass complexity checks"?</h1>
      <div class="eximg">
        <div>
          <img alt="change_reset_password_error_history_en" src="../../assets/change_reset_password_error_history_en.png">
        </div>
      </div>
      <h3>Answer</h3>
      <p class="answer">
        To set a password, the following conditions must be met:<br />
        ・8 characters or more<br />
        ・Passwords different from last three time
      </p>
    </div>
    <br /><br />
    <div class="link-div-bottom">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<router-link to="./ChangePasswordinvalid"><img id="forward" src="/arrow_forward.png" v-on:mouseover="imageForwardChange" v-on:mouseleave="imageForwardReturn" /></router-link>
    </div>
  </div>
</template>
<style>
.qa {
  min-width:800px;
  width:auto !important;
  width:800px;
  border-style:solid;
  border-width:1px;
  border-color:gray;
  border-radius:3px;
  padding:20px;
}
.answer {
  font-size:20px;
}

.link-div-top {
  text-align:left;
}

.link-div-bottom {
  text-align:center;
}

.eximg {
  display: flex;
  flex-direction: row;
}

#forward {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

#back {
  border-style:solid;
  border-width:1px;
  border-color:gray;
}

</style>
<script>
export default {
  methods: {
    imageForwardChange: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward_on.png'
    },
    imageForwardReturn: function () {
      var img = document.getElementById('forward')
      img.src = '/arrow_forward.png'
    },
    imageBackChange: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back_on.png'
    },
    imageBackReturn: function () {
      var img = document.getElementById('back')
      img.src = '/arrow_back.png'
    }
  }
}
</script>
